import { Storage } from "@capacitor/storage";
import {
  ClientInstance,
  PushStatus,
} from "@connectedliving/common/lib/firestore/ClientInstance";
import ClientInstanceConverter from "@connectedliving/common/lib/firestore/ClientInstanceConverter";
import { clientInstancePath } from "@connectedliving/common/lib/firestore/firestorePathBuilders";
import { FIRESTORE_RANDOM_ID_LENGTH } from "@connectedliving/common/lib/utilities/firebaseConstants";
import dontAwait from "@connectedliving/common/lib/utilities/lang/dontAwait";
import uniqueGlobalId from "@connectedliving/common/lib/utilities/uniqueGlobalId";
import { useEffect, useState } from "react";
import { createContainer } from "src/utilities/createContainer";
import useFirestoreDocument, {
  FirestoreDocument,
} from "../utilities/data/useFirestoreDocument";
import environment from "../utilities/environment";
import FirebaseAppContainer from "./firebase/FirebaseAppContainer";
import I18nContainer from "./i18n/I18nContainer";

const clientInstanceStorageKey = `${environment().firebaseProjectId()}:clientInstanceId`;

type ClientInstanceContainerState = {
  clientInstance: FirestoreDocument<ClientInstance>;
};

export function useClientInstance(): ClientInstanceContainerState {
  const { firebaseApp, authUser, onLogOut } =
    FirebaseAppContainer.useContainer();
  const { currentLocale } = I18nContainer.useContainer();
  const [clientInstanceId, setClientInstanceId] = useState<string | undefined>(
    undefined,
  );

  useEffect(() => {
    async function getOrGenerateClientInstanceId() {
      if (!authUser) {
        await Storage.remove({ key: clientInstanceStorageKey });
        return;
      }

      const { value: storedClientInstanceId } = await Storage.get({
        key: clientInstanceStorageKey,
      });

      if (storedClientInstanceId) {
        setClientInstanceId(storedClientInstanceId);
        return;
      }

      const newRandomId = uniqueGlobalId({
        length: FIRESTORE_RANDOM_ID_LENGTH,
      });
      setClientInstanceId(newRandomId);
      await Storage.set({
        key: clientInstanceStorageKey,
        value: newRandomId,
      });
    }

    dontAwait(getOrGenerateClientInstanceId());
  }, [authUser]);

  const clientInstance = useFirestoreDocument(
    firebaseApp &&
      clientInstanceId !== undefined &&
      firebaseApp
        .firestore()
        .doc(clientInstancePath({ clientInstanceId }))
        .withConverter(ClientInstanceConverter),
  );

  useEffect(() => {
    if (
      clientInstance.loadingState === "ready" &&
      !clientInstance.data.exists &&
      authUser
    ) {
      dontAwait(
        clientInstance.data.ref.set({
          createdAt: "serverTimestamp",
          updatedAt: "serverTimestamp",
          userId: authUser.uid,
          locale: currentLocale,
          pushConfig: {
            pushStatus: PushStatus.initial,
            fcmToken: null,
            pushNetwork: null,
            pushLatestError: null,
            pushDisabledReason: null,
          },
          translationConfig: { enabled: false },
        }),
      );
    }
  }, [
    authUser,
    clientInstance.data?.exists,
    clientInstance.data?.ref,
    clientInstance.loadingState,
    currentLocale,
  ]);

  useEffect(
    () =>
      onLogOut(async () => {
        if (!authUser?.uid) return;
        await Storage.remove({ key: clientInstanceStorageKey });
      }),
    [authUser?.uid, firebaseApp, onLogOut],
  );

  return {
    clientInstance,
  };
}

const ClientInstanceContainer = createContainer(useClientInstance);
export default ClientInstanceContainer;

import { BuildingPart } from "@connectedliving/common/lib/firestore/BuildingPart";
import { ChannelNotificationsState } from "@connectedliving/common/lib/firestore/ChannelNotificationsState";
import { supportedCountries } from "@connectedliving/common/lib/firestore/supportedCountries";
import { LanguageCode } from "@connectedliving/common/lib/firestore/supportedLanguages";
import { teamUnsecuredUntilMemberCount } from "@connectedliving/common/lib/firestore/Team";
import { TeamLocation } from "@connectedliving/common/lib/firestore/TeamLocation";
import { UserProfile } from "@connectedliving/common/lib/firestore/UserProfile";
import formatFullName from "@connectedliving/common/lib/utilities/formatFullName";
import castAs from "@connectedliving/common/lib/utilities/lang/castAs";
import { IonIcon, IonText } from "@ionic/react";
import firebase from "firebase/compat/app";
import { flameSharp, flashSharp, languageOutline } from "ionicons/icons";
import { defaultFormatters } from "src/state/i18n/i18nConfig";
import { GeocodeAddressComponentType } from "src/utilities/googlePlaces/getGeocodeAddressComponentDict";
import { FormatValueTagFunction } from "src/utilities/i18n/makeFormatValueTagFunction";
import { makeLocale } from "src/utilities/i18n/makeLocale";

export function translations(
  t: FormatValueTagFunction,
  formatters: ReturnType<typeof defaultFormatters>,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
) {
  const common = {
    add: t`Add`,
    search: t`Search for a neighbor`,
    inviteNeighbor: t`Invite a neighbor`,
    create: t`Create`,
    update: t`Update`,
    done: t`Done`,
    back: t`Back`,
    errorMessage: t`Something went wrong, please try again`,
    httpsErrorMessage: (error: { message: string; code: string }) =>
      t`Error: ${error.message} (${error.code})`,
    name: t`Name`,
    next: t`Next`,
    copiedToClipboard: t`Copied to clipboard`,
    ok: t`Ok`,
    skip: t`Skip`,
    cancel: t`Cancel`,
    save: t`Save`,
    members: t`Members`,
    genericError: t`An error occured:`,
    connectedLivingMessenger: t`Connected Living Messenger`,
    join: t`Join`,
    confirm: t`Confirm`,
    you: t`You`,
    contactSupport: t`Contact Support`,
    retry: t`Retry`,
    buildingParts: castAs<Record<BuildingPart, string>>({
      "front-house": t`Vorderhaus`,
      "back-house": t`Hinterhaus`,
      "rear-house": t`Quergebäude`,
      "garden-house2": t`Gartenhaus`,
      "garden-house": t`Remise`,
      "house-wing": t`Seitenflügel`,
      "house-wing-left": t`Seitenflügel Links`,
      "house-wing-right": t`Seitenflügel Rechts`,
      "side-house": t`Seitenhaus`,
      "side-house-left": t`Seitenhaus Links`,
      "side-house-right": t`Seitenhaus Rechts`,
      "first-hof": t`1. Hof`,
      "second-hof": t`2. Hof`,
      "third-hof": t`3. Hof`,
      "fourth-hof": t`4. Hof`,
      "fifth-hof": t`5. Hof`,
      "sixth-hof": t`6. Hof`,
    }),
    supportedCountries,
    floorName: (i: number) => {
      const j = i % 10;
      const k = i % 100;
      if (i === -1) {
        return `Basement`;
      }
      if (i === 0) {
        return `Ground Floor`;
      }
      if (j === 1 && k !== 11) {
        return `${i}st Floor`;
      }
      if (j === 2 && k !== 12) {
        return `${i}nd Floor`;
      }
      if (j === 3 && k !== 13) {
        return `${i}rd Floor`;
      }
      return `${i}th Floor`;
    },
    language: castAs<Record<LanguageCode, string>>({
      de: t`German`,
      en: t`English`,
    }),
    locales: castAs<Record<"en-us" | "de" | "en-de" | "en-gb", string>>({
      "en-us": t`English (US)`,
      "en-de": t`English (Germany)`,
      de: t`Deutsch`,
      "en-gb": t`English (UK)`,
    }),
    validations: {
      isRequired: t`is required`,
      validEmailAddress: t`must be an email address`,
      characterMinimum: (min: number) => t`must be at least ${min} character`,
      characterMaximum: (max: number) =>
        t`must not be longer than ${max} characters`,
      numberMinimum: (min: number) => t`must be greater than ${min}`,
      numberMaximum: (max: number) => t`must be less than ${max}`,
      mustBeWholeNumber: t`must be a whole number`,
    },
    startNewCommunityInfoModal: {
      title: t`Starting a new Community`,
      body: (onClickFn: () => void) => (
        <>
          <p>
            A <strong>Community</strong> is a private online space for you and
            your neighbors that can only be joined{" "}
            <strong>by invitation</strong>.
          </p>

          <p>
            <em>
              Note: for new communities (with fewer than{" "}
              {teamUnsecuredUntilMemberCount} members) the invite code is
              public, to make it easy to get everyone on board.
            </em>
          </p>

          <p>
            You can create <strong>Channels</strong> in your Community for
            topics which are relevant to you. Channels are group chats that
            anyone in the community can find and join.
          </p>

          <p>
            And when you need to speak privately with someone, you can send them
            a <strong>Direct Message</strong>.
          </p>

          <p>
            <strong>Choose to start a new Community if:</strong>
          </p>
          <ul>
            <li>You are the first person here</li>
            <li>
              You would like to invite others to join you (we will send you
              printed invitations to make this easier)
            </li>
          </ul>
          <p>
            If you&apos;re unsure, feel free to{" "}
            <IonText color="primary" onClick={() => onClickFn()}>
              <u>contact support</u>
            </IonText>{" "}
            and we&apos;ll be happy to help you.
          </p>
        </>
      ),
    },
    addressComponentNames: castAs<Record<GeocodeAddressComponentType, string>>({
      country: t`Country`,
      administrative_area_level_1: t`State`,
      postal_code: t`Postcode`,
      locality: t`City`,
      street_number: "Street number",
      route: t`Street name`,
    }),
    tipBanners: {
      bestResults: { text: t`Best results!`, icon: flameSharp },
      quickest: { text: t`Quickest!`, icon: flashSharp },
    },
    cantReachNetwork: t`Looks like you're offline`,
    unknownUser: t`(Unknown user)`,
    systemUser: t`Connected Living`,
  };
  return {
    EditTeamPage: {
      title: t`Edit Community`,
      numberOfApartments: t`Number of apartments`,
      teamUpdated: t`Your community has been updated`,
      buildingsWhiteListLabel: t`Buildings in your community`,
      countBuildingPartsSelected: (num: number) => t`${num} buildings selected`,
    },
    AddTeamLocationPage: {
      form: {
        streetName: t`Street name`,
        streetNumber: t`Street number`,
        city: t`City`,
        state: t`State`,
        country: t`Country`,
        postcode: t`Postcode`,
      },
      title: t`Add An Address`,
      teamLocationUpdated: ({
        streetName,
        streetNumber,
      }: {
        streetName: string;
        streetNumber: string;
      }) => t`${streetName} ${streetNumber} has been added`,
    },
    EditTeamLocationPage: {
      form: {
        streetName: t`Street name`,
        streetNumber: t`Street number`,
        city: t`City`,
        state: t`State`,
        country: t`Country`,
        postcode: t`Postcode`,
      },
      title: t`Edit Address`,
      teamLocationUpdated: t`Your address has been updated`,
    },
    TeamAdminPage: {
      apartments: t`Apartments`,
      members: t`Members`,
      communityAdmin: t`Community creator`,
      locationLabel: (
        teamLocations: firebase.firestore.QueryDocumentSnapshot<TeamLocation>[],
      ) => (teamLocations.length === 1 ? t`Address` : t`Addresses`),
      buildingsLabel: (buildings: BuildingPart[]) =>
        buildings.length === 1 ? t`Building` : t`Buildings`,
      location: (teamLocation: TeamLocation) => (
        <>
          {teamLocation.streetName} {teamLocation.streetNumber}
          <p>
            {teamLocation.postcode} {teamLocation.city}
          </p>
        </>
      ),
      editTeamButton: t`Edit your community`,
      unknownAdmin: t`No current admin`,
      yourCommunity: t`Your Community`,
      addLocationButton: t`Add address`,
      addLocationPlaceholder: t`Type a new address`,
      contactAdmin: (
        userProfileData: UserProfile | undefined,
        onClickFn: () => void,
      ) =>
        userProfileData ? (
          <>
            To make changes to your community, send a message to{" "}
            {formatFullName(userProfileData)} or{" "}
            <IonText color="primary" onClick={() => onClickFn()}>
              contact us
            </IonText>
            .
          </>
        ) : (
          <>
            To make changes to your community please{" "}
            <IonText color="primary" onClick={() => onClickFn()}>
              contact us
            </IonText>
            .
          </>
        ),
      contactUs: (onClickFn: () => void) => (
        <>
          Only you can make changes to your community. Questions?{" "}
          <IonText color="primary" onClick={() => onClickFn()}>
            Contact us
          </IonText>
          .
        </>
      ),
      teamLocationUpdated: ({
        streetName,
        streetNumber,
      }: {
        streetName: string;
        streetNumber: string;
      }) => t`${streetName} ${streetNumber} has been added`,
    },
    SearchableUserProfileList: {
      noResults: t`Can't find anyone by that name`,
    },
    UserProfilesListPage: { people: t`People` },
    ViewUserProfilePage: {
      edit: t`Edit`,
      labels: {
        name: t`Name`,
        address: t`Address`,
        building: t`Building`,
        floor: t`Floor`,
        extraLocationData: t`Additional information`,
        preferredLanguage: t`Preferred language`,
      },
      messageVisibilityNotification: () => (
        <>
          Messages between you and Connected Living Support are visible to the
          Connected Living team.
          <br />
          <br /> Send us a message and a human will respond (wir sprechen auch
          Deutsch ❤️)
        </>
      ),
      noLanguageSelected: t`No language selected`,
      sendDirectMessage: t`Send direct message`,
      title: t`Profile`,
    },
    AuthPage: {
      title: t`Connect with your neighbors`,
      subtitle: t`Enter your email address to sign in or create an account`,
    },
    common,
    ChannelsListPage: {
      channels: t`Channels`,
      directMessages: t`Direct Messages`,
      viewMoreChannels: t`View more channels`,
      newDirectMessage: t`New direct message`,
      confirm: t`Change team`,
      cancel: t`Close`,
      growCommunityCard: {
        superTitle: t`Grow Your Community`,
        title: (numberOfNeighbors: number) =>
          numberOfNeighbors === 1 ? (
            <strong>Let&apos;s get started</strong>
          ) : (
            <>
              {" "}
              <strong>{numberOfNeighbors} neighbors</strong>
            </>
          ),
        body: (numberOfNeighbors: number, numberOfApartments: number) =>
          numberOfNeighbors === 1 ? (
            <>
              You&apos;re the first person here! Click here to invite your
              neighbors
            </>
          ) : (
            <>
              have joined out of {numberOfApartments} apartments total! Click
              here to invite the rest
            </>
          ),
      },
    },
    EditMyUserProfilePage: {
      profileSaved: t`Your profile has been saved`,
      atSignInvalidNameInput: t`names cannot include "@"`,
      title: t`Edit Your Profile`,
      changeImage: t`Change your image`,
      addImage: t`Add image`,
      placeHolders: {
        firstName: t`What's your first name?`,
        lastName: t`And your last name?`,
        address: t`Address`,
        floor: t`Floor`,
        building: t`Building`,
        apartmentNumber: t`Apartment number, c/o, etc`,
      },
      headers: {
        name: t`Name`,
        location: t`Location`,
      },
    },
    TeamCreatePage: {
      buttonName: t`Create Community`,
      additionalInformation: t`You can change this later and add additional addresses`,
      contactSupport: (supportEmailElement: JSX.Element) => (
        <>Questions? Contact us at {supportEmailElement}</>
      ),
      helperTexts: {
        state: t`Only addresses in Germany are supported`,
        numberOfApartments: t`This helps us better support you in growing your community`,
      },
      cardLabels: {
        address: t`Address`,
        numberOfApartments: t`Number of Apartments`,
      },
      errorMessage: t`Something went wrong, please try again`,
      isRequired: t`is required`,
      subtitle: t`First person here? Start a new community, then invite your neighbors to join you.`,
      title: t`Start a new community`,
      step: (i: number) => t`Step ${i}`,
      skip: t`Skip`,
      slides: {
        registerYourAddress: {
          title: t`Register your address`,
          body: t`Create a new Connected Living community for your address which your neighbors can join.`,
          imgAlt: t`Location pin nestled on a street corner`,
        },
        receiveInvitations: {
          title: t`Order printed invitations`,
          body: t`We offer printed Starter Kits to help get your neighbors on board for just a couple of euros. Or if you prefer, download the PDF and print them at home!`,
          imgAlt: t`An invitation laying on a manila envelope`,
        },
        inviteYourNeighbors: {
          title: t`Invite your neighbors`,
          body: t`Drop the invitations in their letterboxes and watch as your community grows!`,
          imgAlt: t`A mailbox & an invitation`,
        },
      },
      searchAddressModal: {
        placeholder: t`What's your address?`,
        submitText: t`Choose address`,
      },
    },
    TeamCreatedSuccessPage: {
      appleStoreImgAlt: t`Apple App Store`,
      playStoreImgAlt: t`Google Play Store`,
      welcome: t`Welcome to`,
      communityIsReady: t`Your new community is ready to go! Download the mobile app to continue and invite your neighbors.`,
      illustrationAltText: t`House community with apartments and people sharing things with a party horn blowing out confetti & streamers atop`,
    },
    NewTeamChannelPage: {
      tooLong: t`Channel name is too long`,
      alreadyExists: t`There is already a channel with this same name`,
      title: t`New Channel`,
      name: t`Name`,
      purpose: t`What's this channel for?`,
      createChannel: t`Create Channel`,
    },
    UserIsBlockedBanner: {
      notification: t`You have blocked this user. You will
      not receive direct messages from them and they will not be notified
      that you blocked them.`,
    },
    ChannelsMessagesHeader: {
      autoTranslate: (
        userProfile: UserProfile,
        languageName: { (language: LanguageCode): string },
      ) =>
        t`Automatic translation to ${
          userProfile.language && languageName(userProfile.language)
        } enabled`,
      autoTranslateDisabled: t`Automatic translation disabled`,
    },
    CLMessageInputSmall: {
      couldNotUpload: t`Could not upload image`,
      inputPlaceholder: t`Type your message`,
      attachFiles: t`Attach files`,
      maxFilesWarning: t`You've reached the maximum number of files`,
      closeEmojiPicker: t`Close emoji picker`,
      openEmojiPicker: t`Open emoji picker`,
    },
    MessageRenderer: {
      cannotUndo: t`This cannot be undone`,
      copyText: t`Copy Text`,
      deleteMessage: t`Delete Message`,
      editMessage: t`Edit Message`,
      reportMessage: t`Report message`,
      reportToast: {
        header: t`Message reported`,
        message: t`A member of our support team may contact you for further information`,
      },
    },
    ChannelPreview: {
      messageDeleted: t`Message deleted`,
    },
    DiscoveryChannelsPage: {
      title: t`Channels`,
      memberOfAll: t`You're already a member of all channels`,
    },
    TeamChannelDetailPage: {
      title: t`Channel Info`,
      leaveChannel: t`Leave Channel`,
      joinChannel: t`Join Channel`,
      muteChannel: t`Mute`,
      unmuteChannel: t`Muted`,
      yes: t`Yes`,
      no: t`No`,
    },
    DirectMessageChannelDetailPage: {
      title: t`Direct Message Info`,
      contactDetails: t`Contact Details`,
    },
    ReportUserButton: {
      buttonText: t`Report User`,
      alert: {
        header: (targetUserName: string) =>
          t`Are you sure you want to report ${targetUserName}?`,
        message: t`A member of our support team may contact you for further information`,
        inputPlaceholder: t`What is the problem?`,
      },
      reportUserToast: {
        success: (targetUserName: string) => t`${targetUserName} reported`,
        error: t`An error occurred`,
      },
    },
    YouWillBeNotifiedWhen: {
      header: t`Who will be notified about new messages?`,
      memberList: t`Only members of this channel`,
      channelNotificationsState: castAs<
        Record<ChannelNotificationsState, string>
      >({
        normal: t`You will be notified`,
        muted: t`You will not be notified, you have muted this channel`,
        userBlocked: t`You will not be notified, you have blocked this user`,
        notAMember: t`You will not be notified, you are not a member of this channel`,
      }),
    },
    WhoCanSeeThis: {
      header: t`Who can see this?`,
      whoCanSee: {
        anyone: t`Anyone in your building can see this`,
        userSupport: t`You and the Connected Living team`,
        private: t`Only you and the other person`,
      },
      whoCanJoin: {
        anyone: t`Anyone in your building can join`,
        noOne: t`No one else can join`,
      },
    },
    ClosedBetaPage: {
      title: t`Join an existing community`,
      subtitle: t`You can only join a community by invitation from another member. If you don’t have an invite code you can contact our support for help.`,
      inviteCard: {
        title: `Where do I find my invite code?`,
        body: (onClickFn: () => void) => (
          <>
            You can only get an invite code from your neighbors. Check your
            letterbox for an invitation, otherwise ask a neighbor who&apos;s on
            the app, or{" "}
            <IonText color="secondary" onClick={() => onClickFn()}>
              <u>contact support</u>
            </IonText>
            .
          </>
        ),
      },
      illustrationAltText: t`an invitation addressed to the neighbors with a pink QR code in the bottom right`,
    },
    UserSettingsPage: {
      title: t`Settings`,
      editProfile: t`Edit Profile`,
      yourCommunity: t`Your Community`,
      shareApp: t`Share the App`,
      privacyPolicy: t`Privacy Policy`,
      language: t`Language`,
      termsOfUse: t`Terms of Use`,
      appVersion: t`App Version`,
      logOut: t`Log Out`,
      copiedToClipboard: t`Copied to clipboard`,
      deleteAccount: t`Delete Account`,
      leaveCommunity: t`Leave Community`,
      contactUs: {
        button: t`Contact Us`,
        alertHeader: t`Hey there! 👋`,
        alertSubheader: t`This will start a chat with a real human`,
        alertMessage: t`Our small team is happy to hear from you 🙂  We'll respond as quickly as possible`,
        alertCancel: t`Cancel`,
        alertConfirm: t`Open chat`,
        supportUserInterface: t`Support Interface`,
      },
      myCommunities: t`My Communities`,
      push: t`Push Notifications`,
    },
    ShareAppModal: {
      title: t`Share Connected Living`,
      body: t`Use the buttons below to share the app with someone who wants to
      start a new community.`,
      displayQrCode: t`Display App QR Code`,
      nativeShareModal: {
        shareDialogTitle: t`Invite a friend to Connected Living`,
        shareText: t`I'm using Connected Living to chat with my neighbors. You should check it out! Click here to get started`,
      },
    },
    InviteNeighborModal: {
      title: t`Invite Your Neighbors`,
      body: t`New members can only join your community using an invite code from an existing member.`,
      howToInvite: t`How to Invite Neighbors`,
      displayInviteCode: t`Display Invite Code`,
      displayQrCode: t`Display QR Code`,
      shareInviteLink: t`Share Invite Link`,
      nativeShareModal: {
        shareDialogTitle: t`Invite a neighbor to Connected Living`,
        shareText: (teamName: string) =>
          `Join our ${teamName} community on Connected Living`,
        shareCodeText: (teamName: string, inviteCode: string) =>
          t`Use this invite code to join our ${teamName} community: ${inviteCode}`,
      },
      requestStarterKit: {
        buttonLabel: t`Order Printed Invitations`,
      },
      printAtHome: {
        buttonLabel: t`Invitations to Print Yourself`,
      },
    },
    HowToInviteNeighborsModal: {
      title: t`How to Grow Your Community`,
      body: () => (
        <p>
          <strong>Whats an invite code?</strong>
          <br />
          Your invite code is like the password for your community
        </p>
      ),
      superTitle: (index: number) => <>Option {index}</>,
      options: {
        printAtHome: {
          title: t`Print invitations yourself`,
          body: t`Got a printer? Download a PDF that you can print and share with your neighbors right away.`,
          tipBanner: common.tipBanners.quickest,
        },
        shareInviteLink: {
          title: t`Share the invite link`,
          body: t`The invite link makes it easy for people who don't have the app to download it and join your community. Perfect to share in a group chat, if you already have one.`,
          tipBanner: null,
        },
        requestStarterKit: {
          title: t`Order printed invitations`,
          body: t`No printer? No problem! Order a Starter Kit of printed invitations and posters from us. It only costs a few euros to cover shipping and materials.`,
          tipBanner: common.tipBanners.bestResults,
        },
        shareInviteCode: {
          title: t`Share the invite code`,
          body: t`Copy the invite code and send it to a neighbor or roommate. Once they download the app they can use it to join your community.`,
          tipBanner: null,
        },
        shareQrCode: {
          title: t`Share the QR code`,
          body: t`The QR code will open the invite link above. Download it as an image to add it to printed materials, e.g. to print a poster or flyers.`,
          tipBanner: null,
        },
      },
    },
    ShowInviteCodeModal: {
      title: t`Your invite code is`,
      body: t` Your invite code is like the password to join your community, only
      share it with neighbors you know.`,
      shareCode: t`Share Invite Code`,
    },
    ShowQrCodeModal: {
      teamInvite: {
        title: t`Invite QR Code`,
        body: t`This QR code opens a page with instructions on how to join your community.`,
      },
      appShare: {
        title: t`App Share QR code`,
        body: t`This QR code opens a page with a link to download the app.`,
      },
    },
    OrderStarterKit: {
      title: t`Order a Starter Kit`,
      description: t`A package of printed invitations that you can drop in your neighbors' mailboxes, and posters that you can hang at the entrances of your house.`,
      includesLabel: t`Includes:`,
      priceLabel: t`Price:`,
      invitations: (count: number) => t`${count} Invitations`,
      postersWithTape: (count: number) => t`${count} Posters with tape`,
      price: (cost: number) =>
        `${formatters.formatNumber(cost, "euroCurrency")} Materials`,
      shippingCost: (cost: number) =>
        t`${formatters.formatNumber(cost, "euroCurrency")} Shipping`,
      buttonText: t`Pay with PayPal`,
      sizeLabel: t`Size:`,
      sizeOptions: {
        small: "S",
        medium: "M",
        large: "L",
      },
    },
    ValuePropositionPage: {
      startingSlide: {
        title: t`When does it help to know your neighbors?`,
        paragraph: t`Everyday problems are a breeze when you can ask your neighbors!`,
        alt: t`one person handing another a box of groceries`,
      },
      helpingHand: {
        title: t`Getting a helping hand`,
        paragraph: t`Carrying something heavy? Can't open a jar? Need a housesitter? Your neighbors will be happy to help.`,
        alt: t`two people carrying a sofa`,
      },
      avoidUnnecessaryPurchases: {
        title: t`Borrowing the tool you need`,
        paragraph: t`Need a ladder? Ask your neighbors, they definitely already have one.`,
        alt: t`a person carrying a ladder`,
      },
      relevantAdvice: {
        title: t`Getting relevant advice`,
        paragraph: t`Rent going up? Maintenance issues? Need a recommendation? Ask your neighbors.`,
        alt: t`two people sitting on a sofa`,
      },
      findingYourPackage: {
        title: t`Finding your package`,
        paragraph: t`No more ringing every doorbell to find the person who has it, just message the building and ask!`,
        alt: t`a person giving someone else a package`,
      },
      button: t`Get Started`,
    },
    UserOnboardingInitialSlide: {
      mainText: t`Create a profile to join your community.`,
      welcome: t`Welcome to`,
      illustrationAltText: t`House community with apartments and people sharing things`,
      getStartedButton: t`Get Started`,
    },
    UserOnboardingDataPrivacySlide: {
      heading: t`Your data is safe here`,
      explanation: () => (
        <>
          <p>
            This is a safe space for your community. Your privacy and your data
            is protected.
          </p>

          <ul>
            <li>Only your neighbors can see your profile and contact you</li>
            <li>Your data is never shared with anyone</li>
            <li>Your data is not used for advertising or tracking</li>
          </ul>
        </>
      ),

      illustrationAltText: t`Heart with data protection illustration`,
    },
    UserOnboardingTranslationLanguageSlide: {
      images: {
        altOne: t`Chat UI with mixed language text`,
        altTwo: t`Chat UI with all text translated to english`,
      },
      headline: t`Message translation`,
      explanation: t`Enable automatic message translation to read and write messages in your language`,
      toggleLabel: t`Enable message translation`,
      selectLabel: t`Translate messages into:`,
    },
    UserOnboardingLocationSlide: {
      illustrationFileName: t`package_en.png`,
      illustrationAlt: t`User Profile with message where neighbor is asking to pick up their package`,
      headline: t`Let your neighbors know where to find you`,
      explanation: t`For example if you receive a package for someone else`,
      teamLocationLabel: t`Address`,
      floorLabel: t`Floor`,
      buildingLabel: t`Building`,
      extraLocationDataLabel: t`Additional information`,
      extraLocationDataPlaceholder: t`Apartment number, c/o, etc.`,
    },
    UserOnboardingNameSlide: {
      illustrationAlt: t`User Profile mockup with profile image, name and address`,
      headline: t`What's your name?`,
      explanation: t`Your name is shown on messages and your profile. Your last name helps people find your door.`,
      noAtSignInNames: t`There are no @ signs in names ❤️`,
      firstName: {
        label: t`First name`,
        isRequired: t`Please tell us how we should address you`,
      },
      lastName: {
        label: t`Last name`,
        isRequired: t`It's really helpful for your neighbors to see the same name that's on your bell`,
      },
    },
    UserOnboardingProfileImageSlide: {
      headline: t`Last step!`,
      explanation: t`Help your neighbors match your name to a face.`,
      uploadPictureButtonText: t`Choose a photo`,
      changePictureButtonText: t`Change your photo`,
    },
    UserOnboardingFinalSlide: {
      welcomeText: (firstName: UserProfile["firstName"]) => (
        <>
          Hi {firstName}! <br /> Nice to meet you!
        </>
      ),
      welcome: t`Welcome to`,
      headerSubtext: t`You're all set!`,
      buttonText: t`Welcome`,
      illustrationAltText:
        "House community with apartments and people sharing things",
    },
    LanguageSettingsPage: {
      title: t`Message Translation`,
      toggletext: (active: boolean) =>
        `Message translation ${active ? "enabled" : "disabled"}`,
      deactivatedText: t`Messages will not be translated`,
      enabledText: (activeLanguage: string) => (
        <p>
          Messages will be automatically translated into {activeLanguage}. You
          can switch translations on and off at any time using the{" "}
          <IonIcon icon={languageOutline} color="dark" size="small" /> button.
        </p>
      ),
      successfulUpdate: t`Updated your message translation preferences`,
      localeLabel: t`Display interface in`,
      localeText: t`This controls the format of dates and numbers, and the language of interface elements like this explanation.`,
      updatedLocale: t`Switched language and formats`,
    },
    ImagePicker: {
      takeAPicture: t`Take a picture`,
      pickFromGallery: t`Choose from gallery`,
    },
    UseBlockUserButtons: { block: t`Block`, unblock: t`Unblock` },
    ShareButton: {
      share: t`Share`,
      copyLink: t`Copy Link`,
      app: { share: t`Share Link`, copy: "Copy Link" },
      invite: { share: t`Share Invite link`, copy: t`Copy Invite Link` },
    },
    SupportUserConversationsPage: {
      noConversationSelected: t`No conversation selected`,
      menu: {
        title: t`Conversations`,
        exit: t`Exit Support Interface`,
        loadingMessage: t`Loading Conversations`,
      },
      detailsMenu: {
        title: t`Details`,
        loadingMessage: t`Loading Details`,
        teamName: t`Team Name`,
        teamMemberCount: t`Team Members`,
        teamId: t`Team ID`,
        userName: t`User Name`,
        userId: t`User Id`,
        userLocation: t`User Location`,
        userProfilePageLink: t`User Profile Page`,
        directMessageConversationPageLink: t`DM Conversation Page`,
        teamAdminPageLink: t`Team Admin Page`,
        membersListPageLink: t`Members List Page`,
        firestore: t`Firestore`,
        connectedLiving: t`Connected Living`,
        openTeamInFirestore: t`Open Team`,
        openUserProfileInFirestore: t`Open UserProfile`,
        removeUser: t`Remove User from Team`,
        removeUserSuccessToast: t`Removed user from team successfully`,
        removeUserErrorToast: (error: unknown) =>
          t`Something went wrong trying to remove the user: ${error}`,
        removalAlert: {
          title: t`Remove this user from this team?`,
          message: t`Are you sure you want to remove this user from this team?`,
          confirm: t`Remove`,
        },
        deleteUser: t`Delete User`,
        deleteUserSuccessToast: t`Deleted user successfully`,
        deleteUserErrorToast: (error: unknown) =>
          t`Something went wrong trying to delete the user: ${error}`,
        deletionAlert: {
          title: t`Delete this user`,
          message: t`Are you sure you want to delete this user?`,
          confirm: t`Delete`,
        },
      },
    },
    useEmailActionSheet: {
      copyEmail: t`Copy email address`,
      openMailApp: t`Open mail app`,
      header: (email: string) => t`Contact ${email}`,
    },
    SearchAddressModal: {
      searchPlaceholder: t`Search Address`,
      noResults: t`Unfortunately, there wasn't any matches for your query`,
      loadingError: t`Looks like you're offline`,
      initialMessage: t`Start typing an address to search (we only support Germany right now)`,
      apiError: t`Unfortunately, something went wrong during your search. Please try again.`,
      missingAddressComponentsError: (keys: GeocodeAddressComponentType[]) =>
        t`The address you selected is missing the following: ${keys
          .map((key) => common.addressComponentNames[key])
          .join(", ")}`,
    },
    GetStartedPage: {
      title: t`Get started`,
      subtitle: t`Looks like you’re not yet a member of a community. What would you like to do next?`,
      inviteCodeCard: {
        title: t`I have an invite code`,
        paragraph: t`Click here if you received an invite in your letterbox or from one of your neighbors.`,
        button: t`Enter Invite Code`,
      },
      checkMyAddressCard: {
        title: t`Check for a Community at my address`,
        paragraph: t`Click here to see if there’s a Connected Living community at your address that you can join. If not, you can start one and we’ll help you invite your neighbors.`,
        button: t`Check My Address`,
      },
      noneOfTheAboveCard: {
        title: t`None of the above...`,
        paragraph: t`We're here to help! Send us an email and we'll answer your questions and help you get started.`,
        button: t`Email Us`,
      },
    },
    CheckMyAddressPage: {
      title: t`Check My Address`,
      subtitle: t`Enter your address below to check for a Connected Living Community.`,
      cards: {
        address: {
          placeholder: t`Enter your address`,
          button: t`Check`,
          modalSubmit: t`Check Address`,
        },
        inviteCode: {
          title: t`I have an invite code`,
          text: t`Install the mobile app and enter your invite code to join your community.`,
        },
      },
    },
    CheckMyAddressResultsPage: {
      preTitle: t`Communities at`,
      subtitle: t`Good news! Your neighbors have started a community on Connected Living!`,
      noResultsSubtitle: t`Nobody is using Connected Living at this address yet.`,
      cards: {
        communityResultCard: {
          installAppAndJoinWithInviteCode: t`Install the app and enter this invite code to join:`,
          installMobileApp: t`Install the app and enter the invite code to join this
              community`,
        },
        getHelp: {
          title: t`I need help`,
          text: t`We’re here for you! Send us an email and we’ll answer your questions and help you get started.`,
        },
        noResults: {
          title: t`I want to start a new community`,
          paragraph: t`Sign up your address and invite your neighbors to use Connected Living. Inviting everyone is easy: order printed invitations to put in each letterbox or download a PDF to print yourself.`,
          button: t`Create Community`,
        },
        joinWaitingList: {
          title: t`Tell me when a community is started here`,
          text: t`Don't want to be the first? Enter your email here to be notified when a community is started at this address.`,
          button: t`Notify Me`,
          email: {
            label: t`Email`,
          },
          waitingListJoinedSuccessfully: t`Your email was added to the waiting list!`,
        },
      },
      errors: {
        noGeocodeResults: t`Couldn't find what you were looking for.`,
        invalidTeamLocation: t`Unfortunately, we couldn't validate your address. Please try again.`,
        errorFirestoreResults: t`Couldn’t get search results. Are you offline?`,
      },
    },
    ComunityResultCardContent: {
      numberOfMembersAndApartments: ({
        numberOfMembers,
        numberOfApartments,
      }: {
        numberOfMembers: number;
        numberOfApartments: number;
      }) => (
        <IonText color="aubergine-text">
          {t`${numberOfMembers}`}
          {numberOfMembers === 1 ? " member" : " members"} /{" "}
          {t`${numberOfApartments}`}
          {numberOfApartments === 1 ? " apartment" : " apartments"}
        </IonText>
      ),
      founded: (createdAt: number) =>
        t`Founded ${formatters.formatDate(
          new Date(createdAt),
          "monthAndYear",
        )}`,
    },
    JoinTeamPage: {
      inviteCodeTitle: t`I have an invite code`,
      paragraph: t`You can only join a community by invitation from another member. If you don’t have an invite code you can contact our support for help.`,
      subtitle: t`Join`,
      getHelpCardTitle: t`I don't have a code`,
      getHelpCardText: t`Ask one of your neighbors or contact our support and we’ll help get you set up.`,
      errors: {
        faultyUrlParams: t`We couldn't find what you were looking for. Please try again.`,
        potentiallyOffline: t`Something went wrong. Are you offline?`,
      },
    },
    InviteCodeFormCard: {
      errorOccurred: t`An error occurred`,
      incorrectInviteCode: t`Looks like your code is incorrect`,
      inviteCode: t`Invite Code`,
      joinButton: t`Join`,
      needHelp: t`Need help?`,
    },
    DeleteAccountActionSheet: {
      header: t`Why do you want to delete your account?`,
      reasons: {
        justTryingOut: t`I was just trying out the app`,
        dontNeedItAnymore: t`I don't need it anymore`,
        badExperience: t`I had a bad experience`,
        notSay: t`I'd rather not say`,
      },
      confirmationAlert: {
        header: t`Are you sure you want to delete your account?`,
        subHeader: t`Be careful, you can't undo this.`,
        confirmDeletionButton: t`Delete Account`,
      },
    },
    TeamSwitcher: {
      searchbarPlaceholder: t`Search communities`,
    },
    TeamLeavePage: {
      title: t`Leave Community`,
      submit: t`I'm sure I want to leave`,
      inputs: {
        reason: {
          label: (teamName: string) => t`Why do you want to leave ${teamName}?`,
          options: {
            wrongCommunity: t`I joined the wrong community`,
            moved: t`I moved away`,
            noLongerWantToBe: t`I had a bad experience`,
            unknown: t`I'd rather not say`,
          },
        },
      },
    },
    OnboardingActionSheetButtons: {
      localeSwitch: t`Change language`,
    },
    MessageComposer: {
      inputPlaceholder: t`Message`,
    },
    MessageListItem: {
      deleted: t`Deleted`,
    },
    RequireUpdatedClient: {
      title: t`Your app needs an update`,
      paragraph: t`Please update to the latest version to continue using Connected Living`,
      updateButton: t`Update App`,
    },
    PushNotificationSettingsPage: {
      title: t`Push Notifications`,
      resetPushToken: {
        action: t`Reset Push System`,
        description: t`Reset the push notifications system. This can help if you're not receiving push notifications`,
        success: t`The push notification system was reset`,
      },
      copyPushToken: {
        action: t`Copy Push Token`,
        success: t`Push token copied`,
      },
      pushToken: {
        label: t`Push Token`,
      },
    },
  };
}

export default makeLocale({
  tag: "en-us",
  formatters: defaultFormatters,
  translations,
});

import { FCM } from "@capacitor-community/fcm";
import {
  ActionPerformed,
  PushNotifications,
  PushNotificationSchema,
  Token,
} from "@capacitor/push-notifications";
import {
  ClientInstance,
  ClientInstanceTranslationConfig,
  PushConfig,
  PushDisabledReason,
  PushNetwork,
  PushStatus,
} from "@connectedliving/common/lib/firestore/ClientInstance";
import { getStringLiteral } from "@connectedliving/common/lib/firestore/dataHelpers";
import DirectMessageChannelConverter from "@connectedliving/common/lib/firestore/DirectMessageChannelConverter";
import { parseDirectMessageChannelPath } from "@connectedliving/common/lib/firestore/firestorePathBuilders";
import { languages } from "@connectedliving/common/lib/firestore/supportedLanguages";
import TeamChannelMembershipConverter from "@connectedliving/common/lib/firestore/TeamChannelMembershipConverter";
import dontAwait from "@connectedliving/common/lib/utilities/lang/dontAwait";
import {
  directMessageChannelMessagesPageUrl,
  teamChannelMessagesPageUrl,
} from "@connectedliving/common/lib/utilities/urlBuilders";
import { isPlatform } from "@ionic/react";
import firebase from "firebase/compat/app";
import { isEqual, pick } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router";
import I18nContainer from "src/state/i18n/I18nContainer";
import { createContainer } from "src/utilities/createContainer";
import parseNewMessagePushNotificationPayload from "src/utilities/parseNewMessagePushNotificationPayload";
import usePluginListener from "src/utilities/usePluginListener";
import { isDataAvailable } from "../utilities/data/Loadable";
import ClientInstanceContainer from "./ClientInstanceContainer";
import FirebaseAppContainer from "./firebase/FirebaseAppContainer";
import MixpanelClientContainer from "./mixpanel/MixpanelContainer";
import TranslationConfigContainer from "./TranslationConfigContainer";
import usePrevious from "./usePrevious";

export function usePushNotifications(): {
  pushNotificationDeviceId: string | undefined;
  resetPushToken: () => Promise<void>;
} {
  const i18n = I18nContainer.useContainer();
  const { authUser, firebaseApp, onLogOut } =
    FirebaseAppContainer.useContainer();
  const [pushNotificationDeviceId, setPushNotificationDeviceId] = useState<
    string | undefined
  >(undefined);
  const [fcmToken, setFcmToken] = useState<string | undefined>(undefined);
  const history = useHistory();
  const { track } = MixpanelClientContainer.useContainer();
  const {
    showTranslation: translationEnabled,
    preferredLanguage: targetTranslationLanguage,
  } = TranslationConfigContainer.useContainer();
  const { clientInstance } = ClientInstanceContainer.useContainer();
  const logOutInProgress = useRef(false);

  useEffect(() => {
    if (!authUser) return;

    async function requestPushNotificationPermission() {
      if (!isPlatform("capacitor")) return;

      let permStatus = await PushNotifications.checkPermissions();

      if (
        permStatus.receive === "prompt" ||
        permStatus.receive === "prompt-with-rationale"
      ) {
        permStatus = await PushNotifications.requestPermissions();
      }

      if (permStatus.receive === "granted") {
        await PushNotifications.register();
        dontAwait(
          track({
            eventName: "Push Notification Permission Granted",
          }),
        );
      } else {
        dontAwait(
          track({
            eventName: "Push Notification Permission Denied",
          }),
        );
      }
    }

    dontAwait(requestPushNotificationPermission());
  }, [authUser, track]);

  const openRouteForNotification = useCallback(
    async (notification: PushNotificationSchema) => {
      const { channelId, channelType } = parseNewMessagePushNotificationPayload(
        notification.data,
      );

      if (!channelId || !channelType || !authUser) {
        return;
      }

      if (channelType === "messaging") {
        const directMessageChannels = await firebaseApp
          .firestore()
          .collectionGroup("DirectMessageChannels")
          .withConverter(DirectMessageChannelConverter)
          .where("userId", "==", authUser.uid)
          .where("streamChannelId", "==", channelId)
          .get();

        const directMessageChannel = directMessageChannels.docs[0];
        if (!directMessageChannel) return;

        const { teamId } = parseDirectMessageChannelPath(
          directMessageChannel.ref.path,
        );

        history.push(
          directMessageChannelMessagesPageUrl({
            teamId,
            authUserId: authUser.uid,
            otherUserId: directMessageChannel.data().otherUserId,
          }),
        );
        return;
      }

      if (channelType === "team") {
        const teamChannels = await firebaseApp
          .firestore()
          .collectionGroup("TeamChannelMemberships")
          .withConverter(TeamChannelMembershipConverter)
          .where("userId", "==", authUser.uid)
          .where("teamChannelId", "==", channelId)
          .get();

        const teamChannel = teamChannels.docs[0];
        if (!teamChannel) return;

        history.push(
          teamChannelMessagesPageUrl({
            teamId: teamChannel.data().teamId,
            teamChannelId: channelId,
          }),
        );
      }
    },
    [authUser, firebaseApp, history],
  );

  usePluginListener(
    useCallback(
      () =>
        PushNotifications.addListener(
          "pushNotificationActionPerformed",
          (action: ActionPerformed) => {
            // eslint-disable-next-line no-console
            console.info("push action", JSON.stringify(action, undefined, 2));
            dontAwait(openRouteForNotification(action.notification));
          },
        ),
      [openRouteForNotification],
    ),
  );

  const onPushNotificationsRegistration = useCallback(
    ({ value: newPushToken }: Token) => {
      setPushNotificationDeviceId(newPushToken);
    },
    [],
  );
  usePluginListener(
    useCallback(
      () =>
        PushNotifications.addListener(
          "registration",
          onPushNotificationsRegistration,
        ),
      [onPushNotificationsRegistration],
    ),
  );

  const clientInstanceRef = clientInstance.data?.ref;
  const onPushNotificationsRegistrationError = useCallback(
    (error: unknown) => {
      // eslint-disable-next-line no-console
      console.error(`Error registering for Push: ${JSON.stringify(error)}`);

      if (clientInstanceRef) {
        dontAwait(
          clientInstanceRef.update({
            "pushConfig.pushStatus": PushStatus.error,
            "pushConfig.pushLatestError": String(error),
          }),
        );
      }
    },
    [clientInstanceRef],
  );
  usePluginListener(
    useCallback(
      () =>
        PushNotifications.addListener(
          "registrationError",
          onPushNotificationsRegistrationError,
        ),
      [onPushNotificationsRegistrationError],
    ),
  );

  const previousAuthUser = usePrevious(authUser);
  const userId = authUser?.uid || previousAuthUser?.uid;

  useEffect(() => {
    if (!isPlatform("capacitor")) return;
    if (!pushNotificationDeviceId) return;

    if (isPlatform("android")) {
      setFcmToken(pushNotificationDeviceId);
      return;
    }

    if (isPlatform("ios")) {
      FCM.getToken().then(({ token }) => setFcmToken(token));
      return;
    }

    throw new Error(`Unsupported platform`);
  }, [pushNotificationDeviceId]);

  const translationConfig: ClientInstanceTranslationConfig = useMemo(
    () =>
      translationEnabled && targetTranslationLanguage
        ? {
            enabled: true,
            targetLanguage: getStringLiteral(targetTranslationLanguage, {
              permitted: languages,
              fallback: "en",
            }),
          }
        : { enabled: false },
    [targetTranslationLanguage, translationEnabled],
  );

  const pushConfig: PushConfig | undefined = useMemo(() => {
    const data = clientInstance.data?.data();
    if (!data) return undefined;
    if (!fcmToken) return undefined;
    if (!isPlatform("capacitor")) return undefined;

    const pushNetwork = isPlatform("ios")
      ? PushNetwork.ios
      : PushNetwork.android;

    const result: PushConfig = {
      ...data.pushConfig,
      pushStatus: PushStatus.active,
      fcmToken,
      pushNetwork,
    };

    return result;
  }, [clientInstance.data, fcmToken]);

  useEffect(() => {
    async function updateClientInstance() {
      if (!(isDataAvailable(clientInstance) && clientInstance.data.exists))
        return;

      if (!authUser) return;
      if (logOutInProgress.current) return;

      const existingFields = clientInstance.data.data();

      const fieldsToUpdate: Partial<ClientInstance> = {
        userId: authUser.uid,
        locale: i18n.currentLocale,
        ...(pushConfig && { pushConfig }),
        translationConfig,
      };

      const somethingToUpdate = !isEqual(
        fieldsToUpdate,
        pick(existingFields, Object.keys(fieldsToUpdate)),
      );

      if (
        existingFields.pushConfig.fcmToken !==
          fieldsToUpdate.pushConfig?.fcmToken &&
        fieldsToUpdate.pushConfig?.fcmToken
      ) {
        dontAwait(
          track({
            eventName: "Push Device Registered",
            token: fieldsToUpdate.pushConfig?.fcmToken,
          }),
        );
      }

      if (somethingToUpdate) {
        await clientInstance.data.ref.set(
          { ...fieldsToUpdate, updatedAt: "serverTimestamp" },
          { merge: true },
        );
      }
    }

    dontAwait(updateClientInstance());
  }, [
    authUser,
    clientInstance,
    i18n.currentLocale,
    pushConfig,
    track,
    translationConfig,
  ]);

  useEffect(
    () =>
      onLogOut(async () => {
        logOutInProgress.current = true;
        if (!userId) return;
        if (!pushNotificationDeviceId) return;
        if (!clientInstance.data) return;

        await clientInstance.data.ref.update({
          "pushConfig.pushStatus": PushStatus.disabled,
          "pushConfig.pushDisabledReason": PushDisabledReason.logout,
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        });

        await track({
          eventName: "Push Device Deregistered",
          "push token": pushNotificationDeviceId,
        });
      }),
    [
      clientInstance.data,
      firebaseApp,
      onLogOut,
      pushNotificationDeviceId,
      track,
      userId,
    ],
  );

  const resetPushToken = useCallback(async () => {
    // TODO (DEV-1566): after upgrading Capacitor + FCM, try using refreshToken() instead of deleteInstance() - which is currently unimplemented
    await FCM.deleteInstance();
    const { token } = await FCM.getToken();
    setFcmToken(token);
  }, []);

  return { pushNotificationDeviceId, resetPushToken };
}

const PushNotificationsContainer = createContainer(usePushNotifications);
export default PushNotificationsContainer;

import { Storage } from "@capacitor/storage";
import dontAwait from "@connectedliving/common/lib/utilities/lang/dontAwait";
import { useEffect, useState } from "react";
import { createContainer } from "src/utilities/createContainer";
import { LoadingState } from "src/utilities/LoadingState";
import LoggedInUserProfileContainer from "./firebase/LoggedInUserProfileContainer";

export type TranslationConfig = {
  showTranslation: boolean;
  setShowTranslation: (value: boolean) => void;
  preferredLanguage: string | null;
  loadingState: LoadingState;
};

const autoTranslationEnabledStorageKey = "autoTranslationEnabled";

function useTranslationConfig(): TranslationConfig {
  const { userProfile, loadingState } =
    LoggedInUserProfileContainer.useContainer();

  const [showTranslation, setShowTranslation] = useState<boolean>(false);

  useEffect(() => {
    async function run() {
      const { value } = await Storage.get({
        key: autoTranslationEnabledStorageKey,
      });

      if (value) {
        setShowTranslation(JSON.parse(value));
      }
    }

    dontAwait(run());
  }, []);

  function setAndPersistShowTranslation(enabled: boolean) {
    setShowTranslation(enabled);
    return Storage.set({
      key: autoTranslationEnabledStorageKey,
      value: JSON.stringify(enabled),
    });
  }

  return {
    loadingState,
    showTranslation,
    setShowTranslation: setAndPersistShowTranslation,
    preferredLanguage: userProfile?.language || null,
  };
}

const TranslationConfigContainer = createContainer(useTranslationConfig);
export default TranslationConfigContainer;

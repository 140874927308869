import countUnreadMessages from "@connectedliving/common/lib/domain/countUnreadMessages";
import { AppChannelRef } from "@connectedliving/common/lib/firestore/app/AppChannelRef";
import { FirestoreChannelType } from "@connectedliving/common/lib/firestore/FirestoreChannelType";
import firestoreSystemUser from "@connectedliving/common/lib/firestore/firestoreSystemUser";
import { Message } from "@connectedliving/common/lib/firestore/Message";
import { UserProfile } from "@connectedliving/common/lib/firestore/UserProfile";
import streamSystemUser from "@connectedliving/common/lib/stream/streamSystemUser";
import formatFullName from "@connectedliving/common/lib/utilities/formatFullName";
import {
  IonAvatar,
  IonBadge,
  IonItem,
  IonLabel,
  RouterDirection,
} from "@ionic/react";
import classNames from "classnames";
import firebase from "firebase/compat/app";
import React, { useMemo } from "react";
import ReactMarkdown from "react-markdown";
import ChannelAvatarContent, {
  ChannelAvatarContentProps,
} from "src/common/ChannelAvatarContent";
import I18nContainer from "src/state/i18n/I18nContainer";
import { FindUserProfileById } from "../../../state/FindUserProfileById";
import FirebaseAppContainer from "../../../state/firebase/FirebaseAppContainer";
import LastReadTimestampsContainer from "../../../state/team/LastReadTimestampsContainer";
import TranslationConfigContainer from "../../../state/TranslationConfigContainer";
import getTranslatedMessageBody from "../../../utilities/getTranslatedMessageBody";
import css from "./FirestoreChannelPreview.module.css";

export type MessagePreviewProps = {
  message: Message;
  channelType: FirestoreChannelType;
  findUserProfileById: FindUserProfileById;
};

const MessagePreview: React.FC<MessagePreviewProps> = ({
  message,
  channelType,
  findUserProfileById,
}) => {
  const i18n = I18nContainer.useContainer();
  const { authUser } = FirebaseAppContainer.useContainer();
  const { showTranslation, preferredLanguage } =
    TranslationConfigContainer.useContainer();

  if (message.deleted)
    return (
      <p>
        <em>{i18n.t.ChannelPreview.messageDeleted}</em>
      </p>
    );

  const translatedMessageBody: string = getTranslatedMessageBody(
    showTranslation,
    preferredLanguage,
    message,
    authUser?.uid || "",
  );

  let authorName = null;
  if (message.creatorId === authUser?.uid) {
    authorName = `${i18n.t.common.you}: `;
  } else if (channelType === FirestoreChannelType.DirectMessage) {
    authorName = null;
  } else if (
    message.creatorId === firestoreSystemUser ||
    message.creatorId === streamSystemUser
  ) {
    authorName = `${i18n.t.common.systemUser}: `;
  } else {
    const firstName = findUserProfileById(message.creatorId)?.data().firstName;
    if (firstName) {
      authorName = `${firstName}: `;
    } else {
      authorName = `${i18n.t.common.unknownUser}: `;
    }
  }

  let attachmentIcon = null;
  const attachment = message.attachments[0];
  if (attachment) {
    attachmentIcon = "📎";
  }

  const firstLineOfMessageBody = translatedMessageBody.split("\n")[0];
  const messagePreviewText = `${authorName || ""} ${
    attachmentIcon || ""
  } ${firstLineOfMessageBody}`;

  return (
    <ReactMarkdown className={css.markdown}>{messagePreviewText}</ReactMarkdown>
  );
};

export type FirestoreChannelPreviewProps = {
  channel: AppChannelRef;
  routerLink: string;
  routerDirection?: RouterDirection;
  active?: boolean;
  channelAvatar: ChannelAvatarContentProps["content"];
  findUserProfileById: (
    userProfileId: string,
  ) => firebase.firestore.QueryDocumentSnapshot<UserProfile> | undefined;
};

const FirestoreChannelPreview: React.FC<FirestoreChannelPreviewProps> = ({
  routerLink,
  routerDirection,
  channelAvatar,
  active,
  channel,
  findUserProfileById,
}) => {
  const { latestMessage } = channel.channel.data();
  const { getChannelLastReadTimestamp } =
    LastReadTimestampsContainer.useContainer();

  const channelLastReadTimestamp = getChannelLastReadTimestamp(
    channel.channel.ref.path,
  );
  const { messageTimestamps } = channel.channel.data();

  const unreadCount = useMemo(
    () =>
      countUnreadMessages({
        channelLastReadTimestamp,
        messageTimestamps,
        channelType: channel.type,
      }),
    [channel.type, channelLastReadTimestamp, messageTimestamps],
  );

  const otherUserProfile =
    channel.type === FirestoreChannelType.DirectMessage &&
    findUserProfileById(channel.channel.data().otherUserId);
  const formattedName =
    otherUserProfile && formatFullName(otherUserProfile.data());

  const channelName =
    channel.type === "Team" ? channel.channel.data().name : formattedName;

  return (
    <IonItem
      {...{ routerLink }}
      routerDirection={routerDirection ?? "forward"}
      detail={false}
      disabled={false}
      className={classNames({ [css.isActive as string]: active })}
    >
      <IonAvatar
        slot="start"
        className={classNames({
          [css.iconAvatar as string]: channelAvatar.type === "icon",
          [css.channelAvatar as string]: channelAvatar.type !== "icon",
        })}
      >
        <ChannelAvatarContent content={channelAvatar} />
      </IonAvatar>

      <IonLabel>
        <h2>
          <strong>{channelName}</strong>
        </h2>
        {latestMessage && (
          <MessagePreview
            message={latestMessage}
            channelType={channel.type}
            {...{ findUserProfileById }}
          />
        )}
      </IonLabel>

      {unreadCount > 0 && (
        <IonBadge color="primary" slot="end">
          {unreadCount}
        </IonBadge>
      )}
    </IonItem>
  );
};

export default FirestoreChannelPreview;
